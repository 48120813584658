import React, { Component} from 'react';
import '../App.css';
import firebase, { auth, provider } from './../base';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import Timestamp from 'react-timestamp';
import Tasks from './widgets/tasks';
import Notes from './widgets/notes';
import QuickNotes from './widgets/quickNotes';
import EditDoc from './widgets/editDoc';
import Words from './widgets/words';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";


class Index extends Component {
  constructor() {
  super();
  this.state = {
    showTasks: false,
  };
  this.logout = this.logout.bind(this);
  };

  //LogOut Function
  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
      auth.onAuthStateChanged((user) => {
          this.props.history.push("/");
      });
  }

  render(){
    if(this.state.showTasks) {
      return <div><h1>Test</h1></div>;
    }
    return (
    <HashRouter>
    <div className="App">
      <header className="App-header">
        <div className="nav">
          <div className="marginGuide">
           <div className="menu">
             <NavLink to="/tasks"><button>Tasks</button></NavLink>
             <NavLink to="/reminders"><button>Reminders</button></NavLink>
             <NavLink to="/doc"><button>Documents</button></NavLink>
           </div>
            <div className="logout">
             <button onClick={this.logout}>Log Out</button>
            </div>
          </div>
        </div>

        <div className="content">
          <Route path="/tasks" component={Tasks}/>
          <Route path="/reminders" component={Notes}/>
          <Route path="/doc" component={QuickNotes}/>
          <Route path="/edit/:id" component={EditDoc}/>
          <Route path="/words/" component={Words}/>
        </div>

      </header>
    </div>
    </HashRouter>
  );
 }
}

export default Index;
