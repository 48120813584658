import React, { Component} from 'react';
import './../../App.css';
import firebase, { auth, provider } from './../../base';

class Words extends Component {
  constructor() {
  super();
  this.state = {
    wordData: [],
    reload: false,
    fullWord: '',
    shortcut: '',
  };
  this.handleChange = this.handleChange.bind(this);
};

componentWillMount() {
  //Get Words
  const db = firebase.firestore();
  db.collection("words")
  .where("uid", ">=", firebase.auth().currentUser.uid)
  .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({
      wordData: data,
      });
    });

}
handleChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}


//Add Word
addWord = async event => {
  event.preventDefault();
  try {
    //Create Database Record
    const db = firebase.firestore();
    const uid =  firebase.auth().currentUser.uid;
    //Make ID
    const ts = Date.now();
    var id = uid + ts;


    // Add a new word
     db.collection("words").doc(id).set({
      fullWord: this.state.fullWord,
      shortcut: this.state.shortcut,
      uid: firebase.auth().currentUser.uid,
      id: id,
      });
    } catch (error) {
      console.log("Error");
      console.log(error);
    }

    //Refresh Tasks Listing
    const db = firebase.firestore();
    db.collection("words")
    .where("uid", ">=", firebase.auth().currentUser.uid)
    .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({
        wordData: data,
        });
      });

      //Clear Form
      this.setState({
        fullWord: '',
        shortcut: '',
      })

    };

    //Remove Words
    deleteWord = event => {
      //Delete Database Entry
      console.log("Deleting");
      const db = firebase.firestore();

      db.collection("words").doc(event.currentTarget.value)
      .delete();

      //Refresh Tasks Listing
      db.collection("words")
      .where("uid", ">=", firebase.auth().currentUser.uid)
      .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          this.setState({
          wordData: data,
          });
        });

    }




  render(){
    const { wordData } = this.state;
    return (
      <div>
        <div className="tasks">
          <h2>Words</h2>

          {wordData.map(word => (
            <div className="card" key={word.id}>
              <div className="container">
                <div>
                  <h5>
                    <button
                      value={word.id}
                      onClick={this.deleteWord}
                    >X
                    </button>
                  </h5>
                </div>
                <p><b>Full Word: </b>{word.fullWord}</p>
                <p><b>Shortcut: </b>{word.shortcut}</p>
              </div>
            </div>
          ))}

        </div>

        <div className="create">
          <h2>Add a Shortcut</h2>
            <div className="card">
              <div className="container">
                <form onSubmit={this.addWord}>

                  <label>Full Word</label>
                  <input
                     type="text"
                     name="fullWord"
                     value={this.state.fullWord}
                     onChange={this.handleChange}
                   />

                   <label>Shortcut</label>
                   <input
                      type="text"
                      name="shortcut"
                      value={this.state.shortcut}
                      onChange={this.handleChange}
                    />

                  <button type="submit">Add Shortcut</button>
                </form>
              </div>
            </div>
        </div>
      </div>

  );
 }
}

export default Words;
