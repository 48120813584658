import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Add the Firebase services that you want to use
import firebase, { auth, provider } from './base';

import Index from "./pages/index";
import Auth from "./pages/unauth";
import './App.css';
import PrivateRoute from "./PrivateRoute";


class App extends Component {
  state = { loading: true, authenticated: false, user: null, test: "test"};

  componentWillMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });
  }

  render() {
    const { authenticated, loading } = this.state;

    if (loading) {
      return     <div className="App"><header className="App-header"></header></div>;
    }

    return (
      <Router>
        <div>
          <PrivateRoute
            exact
            path="/app"
            component={Index}
            authenticated={authenticated}
          />
          <Route exact path="/" component={Auth} />
        </div>
      </Router>
    );
  }
}

export default App;
