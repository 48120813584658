import React, { Component} from 'react';
import './../../App.css';
import firebase, { auth, provider } from './../../base';

class QuickNotes extends Component {
  constructor(props) {
  super(props);
  this.state = {
    queryResult: [],
    newDoc: '',
    butterflys: false,
  };
  this.createDocument = this.createDocument.bind(this);
};

componentWillMount() {
  //Get Tasks
  const db = firebase.firestore();
  db.collection("documents")
  .where("uid", ">=", firebase.auth().currentUser.uid)
  .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
  .orderBy("uid")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({
      queryResult: data,
      });
    });
  }



  //Create Document
  createDocument(event) {
    event.preventDefault();
      //Create Database Record
      const db = firebase.firestore();
      const uid =  firebase.auth().currentUser.uid;
      //Make ID
      const ts = Date.now();
      var id = uid + ts;

      db.collection("documents").doc(id).set({
       name: "Untitled",
       text: '',
       id: id,
       uid: firebase.auth().currentUser.uid,
     });

     window.location.replace('/app#/edit/' + id);

   }

  render(){
    const { queryResult } = this.state;
    return (
      <div className="docList">
        <div className="tasks">
          <h2>Documents</h2>
          <div className="docBut">
            <button onClick={this.createDocument}>Create New Document</button>
          </div>
          <div className="docBut">
            <a href="/app#/words">
              <button>Manage Shortcuts</button>
            </a>
          </div>
          {queryResult.map(result => (
            <div className="card" key={result.id}>
              <div className="container">
                <div>
                  <div className="title"><h4><b><a href={'/app#/edit/' + result.id}>{result.name}</a></b></h4></div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
  );
 }
}

export default QuickNotes;
