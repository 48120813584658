import React, { Component} from 'react';
import './../../App.css';
import firebase, { auth, provider } from './../../base';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import Timestamp from 'react-timestamp';

class Notes extends Component {
  constructor() {
  super();
  this.state = {
    noteData: [],
    reload: false,
    taskName: '',
    taskDescription: '',
    date: new Date(),
    class: '',
  };
  this.handleChange = this.handleChange.bind(this);
};

componentWillMount() {
  //Get Notes
  const db = firebase.firestore();
  db.collection("notes")
  .where("uid", ">=", firebase.auth().currentUser.uid)
  .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
  .orderBy("uid").orderBy("created", "desc")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({
      noteData: data,
      });
    });

}
handleChange(event) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}


//Create Task
createTask = async event => {
  event.preventDefault();
  try {
    const randomN = Math.floor(Math.random() * Math.floor(10000000000));
    //Create Database Record
    const db = firebase.firestore();
    const uid =  firebase.auth().currentUser.uid;
    //Geneate Group ID
    const id = uid + randomN;
    //Fix time
    var event = new Date(this.state.date);

    var jsonDate = event.toJSON();

    // Add a new document with a generated id.
     db.collection("notes").doc(id).set({
      name: this.state.taskName,
      description: this.state.taskDescription,
      created: jsonDate,
      id: id,
      uid: firebase.auth().currentUser.uid,
      class: this.state.class,
    });

    } catch (error) {
      console.log("Error");
      console.log(error);
    }

    //Refresh Tasks Listing
    const db = firebase.firestore();
    db.collection("notes")
    .where("uid", ">=", firebase.auth().currentUser.uid)
    .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
    .orderBy("uid").orderBy("created", "desc")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({
        noteData: data,
        });
      });

      //Clear Form
      this.setState({
        taskName: '',
        taskDescription: '',
        class: '',
      })

    };

    //Remove Task
    deleteTask = event => {
      //Delete Database Entry
      const db = firebase.firestore();

      db.collection("notes").doc(event.currentTarget.value)
      .delete();

      //Refresh Tasks Listing
      db.collection("notes")
      .where("uid", ">=", firebase.auth().currentUser.uid)
      .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
      .orderBy("uid").orderBy("created", "desc")
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => doc.data());
          this.setState({
          noteData: data,
          });
        });

    }




  render(){
    const { noteData } = this.state;
    return (
      <div>
        <div className="tasks">
          <h2>Notes</h2>

          {noteData.map(note => (
            <div className="card" key={note.id}>
              <div className="container">
                <div>
                  <div className="title"><h4><b>{note.name}</b></h4></div>
                  <h5>
                    <button
                      value={note.id}
                      onClick={this.deleteTask}
                    >X
                    </button>
                  </h5>
                </div>
                <p>{note.description}</p>
                <div className="bottomrow">
                    {note.class ? (
                      <div className="category"><p>{note.class}</p></div>
                    ) : (
                      <div className="category"></div>
                    )}
                </div>
              </div>
            </div>
          ))}

        </div>

        <div className="create">
          <h2>Add a Note</h2>
            <div className="card">
              <div className="container">
                <form onSubmit={this.createTask}>

                  <label>Note Title</label>
                  <input
                     type="text"
                     name="taskName"
                     value={this.state.taskName}
                     onChange={this.handleChange}
                   />

                   <label>Category</label>
                   <input
                      type="text"
                      name="class"
                      value={this.state.class}
                      onChange={this.handleChange}
                      placeholder="optional"
                    />

                   <label>Note Text</label>
                   <textarea
                      name="taskDescription"
                      value={this.state.taskDescription}
                      onChange={this.handleChange}
                    />

                  <button type="submit">Add Note</button>
                </form>
              </div>
            </div>
        </div>
      </div>
  );
 }
}

export default Notes;
