import React, { Component} from 'react';
import './../../App.css';
import firebase, { auth, provider } from './../../base';
import Words from './words';

class EditDoc extends Component {
  constructor(props) {
  super(props);
  this.state = {
    name: "Loading...",
    text: "Loading...",
    docID: this.props.match.params.id,
    wordQuery: [],
    convertedText: "",
  };
  this.convertText = this.convertText.bind(this);
  this.handleChange = this.handleChange.bind(this);
};

componentDidMount() {
  //Get Document
  const db = firebase.firestore();

    //New
    let getData = this;

    var docRef = db.collection("documents").doc(this.state.docID);


    docRef.get().then(function(doc) {
        if (doc.exists) {
            var name = doc.data().name;
            var text = doc.data().text;
            var uid = doc.data().uid

            if(firebase.auth().currentUser.uid != uid) {
              console.log("Access Denied");
              window.location = "/app#/doc";
            } else {
              console.log("Access Granted");
            }

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
        //=============================================

        //======================

        getData.setState({
          name: name,
          text: text,
        });
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });

    //Get listening of Words
    db.collection("words")
    .where("uid", ">=", firebase.auth().currentUser.uid)
    .where("uid", "<=", firebase.auth().currentUser.uid + 0.000001)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.setState({
        wordQuery: data,
        });
      });

      if(this.state.name == "Loading...") {
        window.location.replace('/app#/edit/' + this.state.docID);
      }
}
handleChange(event) {
//====================
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}

saveDoc = async event => {
  event.preventDefault();
  const db = firebase.firestore();

  var updateDoc = db.collection("documents").doc(this.props.match.params.id);

  return updateDoc.update({
      text: this.state.text,
      name: this.state.name,
  })
  .then(function() {
      console.log("Document successfully updated");
  })
  .catch(function(error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
  });

}

convertText(event) {
  var i = 0;

    var contents = this.state.text;
    // Turn String into an Array
    function string_to_array (str) {
       return str.trim().split(" ");
    };
    var text = string_to_array(contents);

    var output = "";
    var x; //Counter
    var arrayLength = this.state.wordQuery.length - 1;
    for (x = 0; x < text.length; x++) {
      for(i = 0; i <= arrayLength; i++) {
        if(text[x] == this.state.wordQuery[i].shortcut){
          text[x] = this.state.wordQuery[i].fullWord;
          }
      }
      output += text[x] + " ";
    }
    this.setState({
      text: output,
    })
}

deleteDoc = event => {
  //Delete Database Entry
  const db = firebase.firestore();

  db.collection("documents").doc(this.props.match.params.id)
  .delete();

  window.location.href = "/app#/doc";

}

render(){
  return (
    <div className="doc">
      <form onSubmit={this.saveDoc}>
      <input
         type="text"
         name="name"
         value={this.state.name}
         onChange={this.handleChange}
       />
        <textarea
        value={this.state.text}
        name="text"
        onChange={this.handleChange}
        />
        <button type="submit">Save Document</button>
      </form>
      <button type="submit" onClick={this.convertText}>Convert Document</button>
      <Words />
      <button type="delete" onClick={this.deleteDoc}>Delete Document</button>
    </div>
  )};
}

export default EditDoc;
