import React, { Component} from 'react';
import '../App.css';
import { withRouter } from "react-router";
import firebase, { auth, provider } from './../base';

class Login extends Component {
  constructor() {
  super();
  this.state = {
    currentItem: '',
    username: '',
    items: [],
    user: null
  }
  this.login = this.login.bind(this);
  this.logout = this.logout.bind(this);
  this.handleChange = this.handleChange.bind(this);
};

componentDidMount() {
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
      this.props.history.push("/app#/tasks");
    }
  });
};

handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
}

login() {
  auth.signInWithPopup(provider)
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
}

logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}

  render(){
  return (
    <div className="App">
    <div className="landing">
      <header className="App-header">
        <h1>Officy</h1>
        <h2>A Virtual Workspace</h2>

        <div className="imgbutton">
          <img src="/google/login.png" onClick={this.login} />
        </div>

      </header>
    </div>
    </div>
  );
 }
}

export default withRouter (Login);
